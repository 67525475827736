import { FC } from 'react';
import styles from './IconicList.module.scss'
import Image from "next/image";
import {clsIf, clss} from "../../src/utils";

type item = {
    icon: string;
    title?: string;
    description?: string;
};

export const IconicList: FC<{
    items: item[] ,
    rowWidth: 1 | 2,
}> = ({ items, rowWidth}) => {
    let rows = new Array<item[]>(items.length / rowWidth);
    for (let i = 0; i < items.length; ++i) {
        let rowNumber = Math.floor(i/rowWidth);
        let row = rows[rowNumber] || [];
        row.push(items[i]);
        rows[rowNumber] = row;
    }

    return (
        <div className={clss(styles.IconicList, clsIf(rowWidth == 2, styles.twoCols))}>{
            rows.map((row) => <div className={styles.row} key={row[0].description}>{
                row.map(item => <div className={styles.item} key={item.description}>
                    <div className={styles.icon}><Image src={`/images/icons/${item.icon}.svg`} width={40} height={40} alt={""} loading={"lazy"}/></div>
                    <div className={styles.rightColumn}>
                        {item.title ? <div className={styles.title}>{item.title}</div> : <></>}
                        {item.description ? <div className={styles.description}>{item.description}</div> : <></>}
                    </div>
                </div>)
            }</div>)
        }</div>
    );
};
