import {Layout} from '../components/Layout/Layout';
import {useEffect, useRef} from 'react';
import {useRouter} from 'next/router';
import styles from './index.module.scss';
import {IconicList} from "../components/IconicList/IconicList";
import {Screen} from "../components/Screen/Screen";
import {TextBlock} from "../components/TextBlock/TextBlock";
import {ActionButton} from "../components/ActionButton/ActionButton";
import bckLaptops from "../public/images/bck-laptops.png";
import bckDesk from "../public/images/bck-desk.png";
import bckJohnDoe from "../public/images/bck-johndoe.png";
import bckSpace from "../public/images/bck-space.png";
import bckSoul from "../public/images/bck-soul.png";
import {clss} from "../src/utils";
import {SocialIcons} from "../components/SocialIcons/SocialIcons";
import * as gtag from "../src/gtag";
import { ImageWithBlur } from '../components/ImageWIthBlur/ImageWithBlur';
import { TextScreen } from '../components/TextScreen/TextScreen';
import {CTAScreen} from "../components/CTAScreen/CTAScreen";


export default function About() {
  const blockAbout = useRef(null);
  const blockContacts = useRef(null);

  let handleRouteChange = (url: string) => {
    /*console.log(`Route is changed to ${url}`)*/
    if (url == "/about/") {
      // blockAbout.current.scrollIntoView(); TODO
    } else if (url == "/contacts/") {
      // blockContacts.current.scrollIntoView(); TODO
    }
  };


  const router = useRouter();
  useEffect(() => {
    handleRouteChange(router.asPath);
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    };
  }, [router.events]);

  return (
    <Layout title={'An Ecosystem For Digital Nomads | Digital Barbaros'} subUrl={''} ldJson={{
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "Digital Barbaros",
      "alternateName": "An Ecosystem For Digital Nomads",
      "url": "https://barbaros.satelstories.com/",
      "description": "Digital Barbaros is the premiere coliving and coworking serving digital nomads worldwide. We offer networking events, online services, and more. Connect today",
      "inLanguage": "en-US",
    }}>
      <Screen id={styles.first}>
        <TextBlock main={true} titleRegular={'Digital '} titleBold={'Barbaros'}
                   description={<>
                     Digital Barbaros is an <b>ecosystem</b> for digital specialists all over the world. Go digital, or go home!
                   </>}
                   slogan={'Where the nomads live'}
                   hint={'Digital nomad is a digital specialist who prefers a mobile lifestyle and is not tied to a specific place of work. You work remotely in digital? You are a nomad!'}
        />
        <div className={styles.childrenContainer}>
        <IconicList items={[
          {icon: 'chair', title: 'Housing', description: 'Live and work with other nomads on the oceanside in Portugal'},
          {icon: 'glasses', title: 'Education', description: 'Step into the digital world and hone your skills'},
          {icon: 'man', title: 'Community', description: 'Meet people that make you want to grow'},
          {icon: 'mask', title: 'Services', description: 'Make your life as a nomad easier with our digital tools'},
        ]} rowWidth={1}/>
      </div>
        <div className={styles.backgroundContainer}>
          <ImageWithBlur src={bckLaptops} alt={"Digital nomads"} loading={"lazy"}
                 layout={"responsive"} objectFit={"cover"} sizes={"(max-width: 768px) 100vw, 652px"}
          />
        </div>
      </Screen>

      <TextScreen>
        We believe in the expertise that has no borders or home port.<br/>
        <b className={styles.secondLine}> Whether you’re a homester or an adventure seeker who can’t stay at the same place for longer than two months,</b><br/>
        Digital Barbaros got you!
      </TextScreen>

      <CTAScreen titleRegular={'Barbaros '} titleBold={'Hut'}
                 slogan={'A home for remote workers'}
                 imageSrc={bckDesk} imageAlt={'Work-life balance'}
                 button={{
                   label: 'Let’s go!',
                   href: '/hut',
                   eventLabel: 'hut',
                 }}
                 description={<>
                   <p>Life is like surfing: balance is the key to having a great time. Do you work remotely and want to
                     travel at the
                     same time? Then Barbaros Hut is your place to be!
                   </p><p>
                   We know what remote workers need when they’re on the go. Barbaros Hut is a <b>nomad housing</b> in
                   Portugal where
                   you can work, rest, and live with fellow nomads worldwide — ocean and surfing included.
                 </p>
                 </>}
      />

      {/*<CTAScreen titleRegular={'Digital Barbaros '} titleBold={'Card'}
                 slogan={'Create your own digital business card'}
                 imageSrc={bckJohnDoe} imageAlt={'Digital business card example'}
                 button={{
                   label: 'Create a card',
                   href: '/card',
                   eventLabel: 'card',
                 }}
                 description={<>
                   <p>Printed business cards are so old-school. Welcome to the digital era! Create a digital business
                     card that you can share with others using a QR code.
                   </p>
                   <ul>
                     <li>Choose contacts you want to share, be it your phone number, email, or social media profiles
                     </li>
                     <li>Add links to the projects you want to present to others</li>
                     <li>Upload a picture and polish your description</li>
                   </ul>
                   <p>
                     No more username retyping and phone number typos — just one click, and you’re connected!
                   </p>
                 </>}
                 right={true} gray={true}
      />*/}

      <TextScreen gray={false}>
        Digital nomads are, first of all, a community — follow us on social media for nomad lifestyle insights, tips,
        and latest news

        <div className={styles.socialRow}>
          <SocialIcons analyticsPlacement={"about"}/>
        </div>
      </TextScreen>

      <CTAScreen titleRegular={'Barbaros '} titleBold={'Academy'}
                 slogan={'Master a digital profession'}
                 imageSrc={bckSpace} imageAlt={'Learning digital profession with ocean view'}
                 button={{
                   label: 'Read more',
                   href: '/academy',
                   eventLabel: 'academy',
                 }}
                 description={
                   <p>Anyone can become a digital nomad. Barbaros Academy stands for practical and efficient
                     courses for those who want to begin their professional journey in IT and Digital. No boring
                     classes — only applied cases!
                   </p>}
                 gray={true}
      >
        <div className={styles.academyTable}>
          <div className={styles.col}>
            Do you want to become a full-stack developer and you have never tried coding?
            <div className={styles.arrow}>⬇️</div>

            Check out Barbaros <b>Course</b> for beginners
          </div>
          <div className={clss(styles.col, styles.odd)}>
            You already have some programming experience but lack teamwork experience?
            <div className={styles.arrow}>⬇️</div>

            Your choice is Barbaros <b>Bootcamp</b>
          </div>
        </div>
      </CTAScreen>

      <CTAScreen titleRegular={'By Nomads '} titleBold={'for Nomads'}
                 slogan={'It’s a lifestyle'}
                 imageSrc={bckSoul} imageAlt={'Digital nomad lifestyle'}
                 description={<p>Digital Barbaros is created to fulfill the needs of remote workers who want to balance
                   their work and travel, expand their horizons, and use all the benefits digital work offers. It’s an
                   exciting lifestyle — and it’s much easier than it looks!
                 </p>}
                 right={true}
      />

      <TextScreen>
        Reach out to us via email at <a href="mailto:welcome@satelstories.com">welcome@satelstories.com</a>. We’ll get back to you shortly!
      </TextScreen>
    </Layout>
  )
}
