import {FC, PropsWithChildren} from "react";
import {Screen} from "../Screen/Screen";
import styles from './TextScreen.module.scss'
import bckWaves from "../../public/images/waves.svg";

export const TextScreen: FC<PropsWithChildren<{ gray?: boolean }>> = ({
  gray,
  children,
}) => {
  return <Screen gray={gray != false} backgroundSrc={bckWaves} className={styles.textScreen}>
    {children}
  </Screen>
}